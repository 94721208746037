<template>
  <div class="pos">
    <div class="pos-cierre text-center mt-2" v-if="cierre != null">
      <!-- Encabezado pos -->
      <div class="header">
        <h2>
          {{ header.razon }}
        </h2>
        <p class="folio">
          Cierre Z #:
          {{ header.folio | folio }}
          <br />
        </p>
        <p class="detalle">
          Nit: {{ header.nit }}
          <br />
          Teléfono: {{ header.telefono }}
          <br />
          Dirección: {{ header.direccion }}
          <br />
          Resolución: {{ header.resolucion }} de
          <br />
          {{ moment(header.resolucion_fecha).format("ll") }}
          <br />
          Actividad economica: {{ header.actividad }}
          <br />
          Generado:
          {{ moment(cierre.fecha).format("ll") }}
        </p>
      </div>
      <div class="body-pos">
        <table>
          <!-- header lista profesionales -->
          <thead>
            <tr>
              <th class="text-left">Profesional</th>
              <th>Cant</th>
              <th>Total facturado</th>
            </tr>
          </thead>
          <!-- lista de profesionales -->
          <tbody>
            <tr v-for="(item, index) in info_profesionales" :key="index">
              <td class="text-left">{{ item.profesional }}</td>
              <td>{{ item.ventas }}</td>
              <td>{{ item.total | currency }}</td>
            </tr>
          </tbody>
          <!-- Header totalizacion detalle -->
          <thead>
            <tr>
              <th
                class="text-left"
                style="border-top: 1px solid rgba(0, 0, 0, 0.12) !important;"
              >
                Totalización
              </th>
              <th style="border-top: 1px solid rgba(0, 0, 0, 0.12) !important;">
                {{ total_ventas_profesionales }}
              </th>
              <th style="border-top: 1px solid rgba(0, 0, 0, 0.12) !important;">
                Total facturado
              </th>
            </tr>
          </thead>
          <!-- Detalle totalizacion -->
          <tbody>
            <tr>
              <th colspan="2" class="text-left">Total profesionales</th>
              <td>{{ total_profesionales | currency }}</td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">Total caja</th>
              <td>{{ cuadre.caja | currency }}</td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">Total productos consumo</th>
              <td>{{ cuadre.consumos | currency }}</td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">
                Total productos venta <small>(contado)</small>
              </th>
              <td>{{ cuadre.ventas | currency }}</td>
            </tr>
            <!-- <tr>
              <th colspan="2" class="text-left">Total bonos regalo</th>
              <td>{{ total_bonos | currency }}</td>
            </tr> -->
            <tr>
              <th colspan="2" class="text-left">Propina</th>
              <td>{{ cuadre.propina | currency }}</td>
            </tr>
          </tbody>
          <!-- Valores calculo totalizacion -->
          <thead>
            <tr>
              <th
                colspan="2"
                class="text-left"
                style="border-top: 1px solid rgba(0, 0, 0, 0.12) !important;"
              >
                Subtotal
              </th>
              <th style="border-top: 1px solid rgba(0, 0, 0, 0.12) !important;">
                {{ (cuadre.valores.subtotal + cuadre.propina) | currency }}
              </th>
            </tr>
            <tr>
              <th colspan="2" class="text-left">IVA</th>
              <th>{{ cuadre.valores.iva | currency }}</th>
            </tr>
            <tr>
              <th colspan="2" class="text-left">Total</th>
              <th>
                {{ (cuadre.valores.total + cuadre.propina) | currency }}
              </th>
            </tr>
          </thead>
        </table>
        <!-- Tabla con metodos de pago -->
        <table>
          <thead>
            <tr>
              <th class="text-left">
                Métodos de pago
              </th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="text-left">Efectivo</th>
              <td>
                {{ caja_efectivo | currency }}
              </td>
            </tr>
            <tr>
              <th class="text-left">Transferencia</th>
              <td>
                {{ caja_transferencia | currency }}
              </td>
            </tr>
            <tr>
              <th class="text-left">Datáfono</th>
              <td>
                {{ caja_datafono | currency }}
              </td>
            </tr>
            <tr>
              <th class="text-left">Bono regalo</th>
              <td>{{ cuadre.metodos.bonos | currency }}</td>
            </tr>
            <tr>
              <th class="text-left">Puntos</th>
              <td>{{ cuadre.metodos.puntos }}</td>
            </tr>
            <tr>
              <th class="text-left">Cortesia</th>
              <td>{{ cuadre.metodos.cortesia | currency }}</td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th
                class="text-left"
                style="border-top: 1px solid rgba(0, 0, 0, 0.12) !important;"
              >
                Total métodos
              </th>
              <th style="border-top: 1px solid rgba(0, 0, 0, 0.12) !important;">
                {{ total_metodos | currency }}
              </th>
            </tr>
          </thead>
        </table>

        <!-- Tabla con demas ingresos y egresos para el centro -->
        <table>
          <thead>
            <tr>
              <th class="text-left">
                Abonos a prestamos (+)
              </th>
              <th>
                {{ total_abonos_prof | currency }}
              </th>
            </tr>
            <tr>
              <th class="text-left">
                Abonos fac por cobrar (+)
              </th>
              <th>
                {{ total_abonos_credito | currency }}
              </th>
            </tr>
            <tr>
              <th class="text-left">
                Abonos proveedores (-)
              </th>
              <th>
                {{ total_abonos_prov | currency }}
              </th>
            </tr>
            <tr>
              <th class="text-left">
                Gastos (-)
              </th>
              <th>
                {{ total_gastos | currency }}
              </th>
            </tr>
          </thead>
        </table>
        <!-- Total general -->
        <table>
          <thead>
            <tr>
              <th class="text-left">Total</th>
              <th class="text-right">
                {{
                  (total_metodos +
                    total_abonos_prof +
                    total_abonos_credito -
                    total_abonos_prov -
                    total_gastos)
                    | currency
                }}
              </th>
            </tr>
          </thead>
        </table>
        <!-- Bonos regalos -->
        <table>
          <thead>
            <tr>
              <th class="text-left">
                Total venta de bonos regalos
                {{
                  (parseFloat(cuadre.bonos.efectivo) +
                    parseFloat(cuadre.bonos.transferencia) +
                    parseFloat(cuadre.bonos.datafono))
                    | currency
                }}
              </th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="text-left">
                Efectivo
              </th>
              <td>
                {{ parseFloat(cuadre.bonos.efectivo) | currency }}
              </td>
            </tr>
            <tr>
              <th class="text-left">
                Transferencia
              </th>
              <td>
                {{ parseFloat(cuadre.bonos.transferencia) | currency }}
              </td>
            </tr>
            <tr>
              <th class="text-left">
                Datafono
              </th>
              <td>
                {{ parseFloat(cuadre.bonos.datafono) | currency }}
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Anticipos -->
        <table>
          <thead>
            <tr>
              <th class="text-left">
                Total anticipos
                {{
                  (parseFloat(cuadre.anticipos.efectivo) +
                    parseFloat(cuadre.anticipos.transferencia) +
                    parseFloat(cuadre.anticipos.datafono))
                    | currency
                }}
              </th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="text-left">
                Efectivo
              </th>
              <td>
                {{ parseFloat(cuadre.anticipos.efectivo) | currency }}
              </td>
            </tr>
            <tr>
              <th class="text-left">
                Transferencia
              </th>
              <td>
                {{ parseFloat(cuadre.anticipos.transferencia) | currency }}
              </td>
            </tr>
            <tr>
              <th class="text-left">
                Datafono
              </th>
              <td>
                {{ parseFloat(cuadre.anticipos.datafono) | currency }}
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Abono prestamos -->
        <table>
          <thead>
            <tr>
              <th class="text-left">
                Abonos pretamos
                {{ total_abonos_prof | currency }}
              </th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="text-left">
                Efectivo
              </th>
              <td>
                {{ cuadre.abonos.prestamos.efectivo | currency }}
              </td>
            </tr>
            <tr>
              <th class="text-left">
                Transferencia
              </th>
              <td>
                {{ cuadre.abonos.prestamos.transferencia | currency }}
              </td>
            </tr>
            <tr>
              <th class="text-left">
                Datafono
              </th>
              <td>
                {{ cuadre.abonos.prestamos.datafono | currency }}
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Abono proveedores -->
        <table>
          <thead>
            <tr>
              <th class="text-left">
                Abonos proveedores
                {{ total_abonos_prov | currency }}
              </th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="text-left">
                Efectivo
              </th>
              <td>
                {{ cuadre.abonos.proveedores.efectivo | currency }}
              </td>
            </tr>
            <tr>
              <th class="text-left">
                Transferencia
              </th>
              <td>
                {{ cuadre.abonos.proveedores.transferencia | currency }}
              </td>
            </tr>
            <tr>
              <th class="text-left">
                Datafono
              </th>
              <td>
                {{ cuadre.abonos.proveedores.datafono | currency }}
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Abonos credito -->
        <table>
          <thead>
            <tr>
              <th class="text-left">
                Abonos fact pendientes cobro
                {{ total_abonos_credito | currency }}
              </th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="text-left">
                Efectivo
              </th>
              <td>
                {{ cuadre.abonos.facturas.efectivo | currency }}
              </td>
            </tr>
            <tr>
              <th class="text-left">
                Transferencia
              </th>
              <td>
                {{ cuadre.abonos.facturas.transferencia | currency }}
              </td>
            </tr>
            <tr>
              <th class="text-left">
                Datafono
              </th>
              <td>
                {{ cuadre.abonos.facturas.datafono | currency }}
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Saldos netos -->
        <table>
          <thead>
            <tr>
              <th class="text-left">Saldos netos</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="text-left">
                Efectivo
              </th>
              <td>
                {{ caja_efectivo_movimientos | currency }}
              </td>
            </tr>
            <tr>
              <th class="text-left">
                Transferencia
              </th>
              <td>
                {{ caja_transferencia_movimientos | currency }}
              </td>
            </tr>
            <tr>
              <th class="text-left">
                Datafono
              </th>
              <td>
                {{ caja_datafono_movimientos | currency }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <v-row>
      <v-col>
        <v-btn dark color="black" class="ml-2 d-print-none" @click="print()"
          >Imprimir</v-btn
        >
        <v-btn color="primary" class="ml-2 d-print-none" @click="close()"
          >Cerrar</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  name: "printCierre",
  data: () => ({
    cierre: null,
    profesionales: [],
    cuadre: {
      pagos: [],
    },
    header: null,
  }),
  methods: {
    loadCierreDetalle() {
      const body = {
        route: "/cierre_z_selected",
        params: { id: this.$route.params.id },
      };
      this.$store
        .dispatch("get_data", body)
        .then((repsonse) => {
          if (repsonse.data.success) {
            this.cierre = repsonse.data.data.cierre;
            this.header = repsonse.data.data.header;
          }
        })
        .finally(() => {
          this.loadCuadreCaja();
        });
    },
    loadCuadreCaja() {
      const body = {
        route: "/cuadre_caja",
        params: {
          date: {
            inicio: this.cierre.fecha,
            fin: this.cierre.fecha,
          },
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) this.cuadre = response.data.data;
      });
    },
    loadProfesionales() {
      const body = {
        route: "/profesionales_sede",
      };
      this.$store.dispatch("axios_get", body).then((response) => {
        if (response.data.success) {
          this.profesionales = response.data.data.filter((p) => {
            return p.profesional;
          });
        }
      });
    },
    close() {
      window.close();
    },
    print() {
      window.print();
    },
    moment(data) {
      return moment(data);
    },
  },
  created() {
    this.loadCierreDetalle();
    this.loadProfesionales();
  },
  computed: {
    info_profesionales() {
      // Lista de profesionales activos
      const profesionales = this.profesionales.map((p) => {
        return {
          id: p.id,
          profesional: p.nombre,
          ventas: 0,
          total: 0,
        };
      });
      // lista de pagos por profesionales activos
      const pagos = this.cuadre.pagos.filter((pago) => {
        return (
          pago.profesional_id != "undefined" &&
          pago.profesional != "sin registro"
        );
      });

      // Asignar pago a profesional correspondiente
      profesionales.forEach((p) => {
        pagos.forEach((pago) => {
          if (p.id == pago.profesional_id) {
            p.ventas = parseFloat(pago.ventas);
            p.total = parseFloat(pago.total);
          }
        });
      });

      // Lista de pago por profesionales desactivados o eliminados
      const profesional_delete = this.cuadre.pagos
        .filter((pago) => {
          return (
            pago.profesional_id == "undefined" &&
            pago.profesional != "sin registro"
          );
        })
        .map((pago) => {
          return {
            id: null,
            profesional: pago.profesional,
            ventas: parseFloat(pago.ventas),
            total: parseFloat(pago.total),
          };
        });
      // Lista profesionales sin registro de nombre
      const no_registro = this.cuadre.pagos.filter((pago) => {
        return (
          pago.profesional_id == "undefined" &&
          pago.profesional == "sin registro"
        );
      });
      // Profesionales sin registro agrupados
      profesional_delete.push({
        id: null,
        profesional: "Profesional eliminado",
        ventas: no_registro.reduce((index, item) => {
          return index + parseFloat(item.ventas);
        }, 0),
        total: no_registro.reduce((index, item) => {
          return index + parseFloat(item.total);
        }, 0),
      });
      return profesionales.concat(profesional_delete);
    },
    total_ventas_profesionales() {
      return this.info_profesionales.reduce((index, item) => {
        return index + item.ventas;
      }, 0);
    },
    total_profesionales() {
      return this.info_profesionales.reduce((index, item) => {
        return index + item.total;
      }, 0);
    },
    total_bonos() {
      return (
        this.cuadre.bonos.efectivo +
        this.cuadre.bonos.transferencia +
        this.cuadre.bonos.datafono
      );
    },
    caja_efectivo() {
      return (
        this.cuadre.metodos.efectivo +
        this.cuadre.bonos.efectivo +
        this.cuadre.anticipos.efectivo
      );
    },
    caja_transferencia() {
      return (
        this.cuadre.metodos.transferencia +
        this.cuadre.bonos.transferencia +
        this.cuadre.anticipos.transferencia
      );
    },
    caja_datafono() {
      return (
        this.cuadre.metodos.datafono +
        this.cuadre.bonos.datafono +
        this.cuadre.anticipos.datafono
      );
    },
    caja_efectivo_movimientos() {
      return (
        this.caja_efectivo -
        this.cuadre.gastos.efectivo +
        this.cuadre.abonos.facturas.efectivo +
        this.cuadre.abonos.prestamos.efectivo -
        this.cuadre.abonos.proveedores.efectivo
      );
    },
    caja_transferencia_movimientos() {
      return (
        this.caja_transferencia -
        this.cuadre.gastos.transferencia +
        this.cuadre.abonos.facturas.transferencia +
        this.cuadre.abonos.prestamos.transferencia -
        this.cuadre.abonos.proveedores.transferencia
      );
    },
    caja_datafono_movimientos() {
      return (
        this.caja_datafono -
        this.cuadre.gastos.datafono +
        this.cuadre.abonos.facturas.datafono +
        this.cuadre.abonos.prestamos.datafono -
        this.cuadre.abonos.proveedores.datafono
      );
    },
    total_metodos() {
      return this.caja_efectivo + this.caja_transferencia + this.caja_datafono;
    },
    total_gastos() {
      return (
        this.cuadre.gastos.efectivo +
        this.cuadre.gastos.transferencia +
        this.cuadre.gastos.datafono
      );
    },
    total_abonos_credito() {
      return (
        this.cuadre.abonos.facturas.efectivo +
        this.cuadre.abonos.facturas.transferencia +
        this.cuadre.abonos.facturas.datafono
      );
    },
    total_abonos_prof() {
      return (
        this.cuadre.abonos.prestamos.efectivo +
        this.cuadre.abonos.prestamos.transferencia +
        this.cuadre.abonos.prestamos.datafono
      );
    },
    total_abonos_prov() {
      return (
        this.cuadre.abonos.proveedores.efectivo +
        this.cuadre.abonos.proveedores.transferencia +
        this.cuadre.abonos.proveedores.datafono
      );
    },
  },
};
</script>

<style scoped>
.pos-cierre {
  font-family: Arial;
  max-width: 80mm;
  margin: 0 auto;
  background: white;
  line-height: normal !important;
  color: black !important;
}
.header {
  font-weight: bold;
}
.header p {
  font-size: 14px;
}
.detalle {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
table {
  width: 100%;
  padding: 8px;
}
table thead th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.body-pos td,
th {
  font-size: 14px !important;
}
</style>
